import { siteStore } from '../stores/site-store'
import { FORMS_APP_DEF_ID } from '../../constants'
import _ from 'lodash'
import {
  CaptchaRequiredError,
  FetchError,
  FetchLimitStatusError,
  InvalidCaptchaError,
  NetworkError,
} from '../errors'
import { LogLevel } from 'raven-js'
import { sanitizePII } from '@wix/bi-logger-sanitizer/dist/src/lib/sanitizers'
import { CAPTCHA_REQUIRED_ERROR } from './constants'
import { HttpResponse } from '@wix/http-client'

const MAX_FETCH_RETRIES = 2
const DELAY_AFTER_FETCH_RETRY = 100
const IMMUTABLES_ERROR_CODES = [400, 429, 403, 401] // skip retry for business logic errors

const shouldTryAgain = (retries, status = -1, disableRetry) =>
  !disableRetry && retries > 0 && !IMMUTABLES_ERROR_CODES.includes(status)

const mapHeaders = (headers: HttpResponse['headers']) => {
  const keyValueHeaders = {}
  _.forEach(headers, (value, key) => (keyValueHeaders[key] = value))
  return keyValueHeaders
}

const captureFetchBreadcrumb = ({
  responseData,
  response,
  endpoint,
  options,
  level,
}: {
  responseData: Object
  response?: HttpResponse
  endpoint: string
  options: RequestInit
  level: LogLevel
}) => {
  const data: {
    url?: string
    method?: string
    responseData?
    status_code?: number
    reason?: string
    authorization?: string
    headers?
  } = {
    url: endpoint,
    method: _.get(options, 'method'),
    responseData,
  }

  if (response) {
    data.status_code = response.status
    data.url = response.config?.url

    if (response.status !== 200) {
      data.reason = response.statusText
      data.authorization = _.get(options, 'headers.Authorization')
      data.headers = _.omit(mapHeaders(response.headers), ['x-seen-by'])
    }
  }

  siteStore.captureBreadcrumb({
    category: 'fetch',
    type: 'http',
    data,
    level,
  })
}

export const fetchWithRetries = <T>(
  baseUrl: string,
  endpoint: string,
  options: RequestInit = {},
  disableRetry = false,
): Promise<T> => {
  const url = `${baseUrl}/${endpoint}`
  const httpClientPromise = 
    options.method === 'GET'
      ? siteStore.httpClient.get<T>(url, { ..._.omit(options, ['method']) })
      : siteStore.httpClient.post<T>(url, options.body, { ..._.omit(options, ['method', 'body']) })
  return new Promise((resolve, reject) => {
    const createErrorObject = (error) => {
      const response: HttpResponse = error?.response
      const data = response?.data

      captureFetchBreadcrumb({
        responseData: data,
        response,
        options,
        endpoint,
        level: 'error',
      })

      let errorObject: Error

      if (response?.status === undefined) {
        errorObject = new NetworkError({ endpoint })
      } else {
        const errorMessage = data?.message || response?.statusText || response?.status
        const isCaptchaError = errorMessage === 'Invalid CAPTCHA'
        const isCaptchaRequiredError = errorMessage === CAPTCHA_REQUIRED_ERROR
        const isLimitStatusError = _.includes(endpoint, 'limit-status')
  
        if (isCaptchaError) {
          errorObject = new InvalidCaptchaError(response)
        } else if (isCaptchaRequiredError) {
          errorObject = new CaptchaRequiredError(response)
        } else if (isLimitStatusError) {
          errorObject = new FetchLimitStatusError({
            status: response.status,
            message: errorMessage,
          })
        } else if (response?.status !== undefined) {
          errorObject = new FetchError({
            endpoint,
            status: response.status,
            message: errorMessage,
          })
        }
      }

      return errorObject
    }
    
    const wrappedHttpClientCall = (retries) => {
      httpClientPromise
        .then((response) => {
          const data = response.data
          captureFetchBreadcrumb({
            responseData: _.isObject(data)
              ? _.mapValues(data, (value) => (_.isString(value) ? sanitizePII(value) : value))
              : data,
            response,
            options,
            endpoint,
            level: 'info',
          })

          resolve(data)
        })
        .catch((error) => {
          if (shouldTryAgain(retries, error?.response?.status, disableRetry)) {
            retry(retries)
          } else {
            const errorObject = createErrorObject(error)
            reject(errorObject)
          }
        })
    }

    const retry = (retries) => {
      setTimeout(() => {
        wrappedHttpClientCall(--retries)
      }, DELAY_AFTER_FETCH_RETRY)
    }

    wrappedHttpClientCall(MAX_FETCH_RETRIES)
  })
}

export const get = <T>(baseUrl, endpoint) =>
  fetchWithRetries<T>(baseUrl, endpoint, {
    method: 'GET',
    headers: {
      Authorization: siteStore.wixApi.site.getAppToken(FORMS_APP_DEF_ID),
      'X-Wix-Client-Artifact-Id': 'wix-form-builder',
    },
  })

export const post = async <T>(baseUrl, endpoint, payload, shouldDisableRetry = false) =>
  fetchWithRetries<T>(
    baseUrl,
    endpoint,
    {
      method: 'POST',
      headers: {
        Authorization: await siteStore.instance(),
        'Content-Type': 'application/json',
        'X-Wix-Client-Artifact-Id': 'wix-form-builder',
      },
      body: payload,
    },
    shouldDisableRetry,
  )
